<template>

  <div>
    <div class="card" v-if="loadGoogleMaps === 0">
      <h3 v-if="stepConfig.title">{{ stepConfig.title }}</h3>
      <h3 v-else>Gleich geschafft, bitte geben Sie Ihre Postleitzahl an:</h3>
      <div class="card__row">
        <div class="card__row__map" >
          <SwissMap v-if="config?.target_country === 'CH'"></SwissMap>
          <AustriaMap v-else-if="config?.target_country === 'AT'"></AustriaMap>
          <GermanyMap v-else></GermanyMap>
        </div>
        <div class="card__row__input">
          <form @submit.prevent="nextStep">
            <div class="full-size-childs">
              <div><input type="text" class="full-size input" required placeholder="Postleitzahl" v-model="val" minlength="4" maxlength="5" ref="autofocus" /></div>
              <div class="input-text">Für die Suche nach regionalen Anbietern</div>
              <div>
                <button type="submit" class="btn btn--notice full-size" >Weiter ></button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-if="loadGoogleMaps === 1">
    <MapStep :isActive="isActive" :config="config" :stepConfig="stepConfig" @nextStep="nextStep"></MapStep>
    </div>
  </div>
</template>

<script>
import Tracking from "../../helpers/Tracking.js";
import MapStep from './MapStep.vue';
import GermanyMap from "../maps/GermanyMap.vue";
import SwissMap from "../maps/SwissMap.vue";
import AustriaMap from "../maps/AustriaMap.vue";


export default {
  name: "ZipStep",
  components: {AustriaMap, SwissMap, GermanyMap, MapStep},
  props: ['isActive', 'config', 'stepConfig',],
  watch: {
    isActive(newVal){
      if (newVal) {
        Tracking.sendPageView(this.config, this.stepConfig);
      }
      if(newVal){
        if(this.loadGoogleMaps === 0){
          this.$refs.autofocus.focus();
        setTimeout(()=>{
          this.$refs.autofocus.focus();
        }, 400);
        }
      }
    }
  },
  emits: ['nextStep'],
  data: ()=>{
    return {
      loadGoogleMaps: window.location.search.indexOf('map=1')>=0 ? 1 : 0,
      val: null
    }
  },
  methods : {
    nextStep(event = null){
      let val = {};
      if(this.loadGoogleMaps == 1)
      {
        val = event;
      } else {
        val['contact_zip'] = this.val;
      }
      
      this.$emit('update', val);
      this.$emit('nextStep', val);
    }
  },
}
</script>

<style scoped>
  .card__row__input {
    max-width: 220px;
    margin-left: 50px
  }
  .card__row__map {
    max-width: 300px;
  }

  .full-size {
    width: 100%;
    box-sizing: border-box;
  }

  h3 {
    margin-top: 0;
  }
  .map {
    width: 100%;
    height: auto;
  }

  .map path {
    fill: #d5dbde;
  }

  .card {
    background: white;
    max-width: 100%;
    width: 800px;
    margin: auto;
    padding: 40px;
    box-sizing: border-box;
  }

  .card h3 {
    font-size: 25px;
    font-weight: normal;
    text-align: center;
  }


  .input-text {
    font-size: .75em;
    opacity: .6;
    margin: 10px 0;
    padding-bottom: 5px;
    font-style: italic;
  }

  .card__row {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card__row > div {
    width: 50%;
  }

  @media (max-width: 500px){
    .map {
      height: auto;
      width: auto;
      max-width: 100%;
      max-height: 200px;
    }

    .card {
      padding: 20px;
    }

    .card__row {
      flex-direction: column-reverse;
    }
    .card__row__map {
      margin-top: 20px;
      text-align: center;
    }

    .card__row__input {
      margin-left: 0;
    }

    .card__row > div {
      width: 80%;
    }

    .btn--angebote {
      font-size: 20px;
    }


  }

</style>
