<template>

  <div>
    <div class="card">
      <h3>Fast geschafft - Bestätigung Ihrer Angaben erforderlich</h3>
      <p v-if="stepConfig.text" style="text-align: center">
        {{stepConfig.text}}
      </p>
      <p v-else style="text-align: center">
        Damit Sie passende Angebote von unseren ausgesuchten Partnern erhalten können, müssen wir Ihre Kontaktdaten auf Ihre Richtigkeit überprüfen.
        Dazu wird Sie in Kürze ein Kundenberater telefonisch kontaktieren.
      </p>
      <!-- Ads -->
      <div v-if="stepConfig.cross_selling === true" class="ads">

    <h4 class="cross-selling-title" v-if="stepConfig.cross_selling_options && stepConfig.cross_selling_options.title">{{stepConfig.cross_selling_options.title}}</h4>
    <component v-if="stepConfig.cross_selling_options.ad_type" :cross_selling_options="stepConfig.cross_selling_options" :config="config" :is="stepConfig.cross_selling_options.ad_type"></component>

    </div>
      </div>
    <iframe v-if="shouldEmbedIframe" :src="iframeSrc" style="position:absolute;left:-9999px"></iframe>
  </div>

</template>

<script>
import Tracking from "../../helpers/Tracking.js";
import AdImageBox from "../ads/AdImageBox.vue";

export default {
    name: "SearchStep",
    props: ["isActive", "config", "stepConfig", "data"],
    emits: ["nextStep"],
    watch: {
        isActive(newVal) {
          if (newVal) {

            if(this.config && this.config.type === 'IMMOBILIE' && this?.data?.verkaufszeitpunkt === 'Momentan nicht'){
              return;
            }

            Tracking.sendPageView(this.config, this.stepConfig);

            if (typeof UC_UI !== 'undefined' ) {
              if (UC_UI.getServicesBaseInfo().filter(data => data.consent.status === true && data.name === 'Google AdWords Conversion').length > 0) {
                this.loadNativendo(this.config);
              }
            }

            // Parse the query parameters from the URL
            const urlParams = new URLSearchParams(window.location.search);
            const utmSource = urlParams.get('utm_source');
            const token = urlParams.get('token');

            // Check if the conditions are met
            if (utmSource === 'cf' && token) {
              this.shouldEmbedIframe = true;
              this.iframeSrc = `https://cfpixl.blue/cf394-ty?token=${token}`;
            }
          } else {
            this.shouldEmbedIframe = false;
          }
        }
    },
    data: () => {
        return {
            val: null,
          shouldEmbedIframe: false,
          iframeSrc: '',
        };
    },
    methods: {
      loadNativendo(config){
        if(config.nativendoId) {
          let script = document.createElement('script');
          script.src = "https://t.nativendo.de/cds/track/event/s/" + config.nativendoId + "?gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_371}";

          let noscript = document.createElement('noscript');
          let img = document.createElement('img');
          img.src = "https://t.nativendo.de/cds/track/event/s/" + config.nativendoId + "/pxl/1?gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_371}";
          img.alt = "";
          img.style = "position:absolute;visibility:hidden;width:1px;height:1px;";
          noscript.appendChild(img);

          document.body.appendChild(script);
          document.body.appendChild(noscript);
        }

      }
    },
    components: { AdImageBox }
}
</script>

<style scoped>

h3 {
  margin-top: 0;
}

h4 {
  text-align: center;
}

.card {
  width: 800px;
  max-width: 100%;
  box-sizing: border-box;
}

.card h3 {
  font-size: 25px;
  text-align: center;
  font-weight: bold;
}

.card__row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px;
}

p {
  line-height: 1.5em;
}

.cross-selling-title {
  font-size: 1.2em;
  margin-top: 70px;
  line-height: 1.5em;
}

</style>
